/**
 * External Dependencies
 */
import axios from "axios";

/**
 * Internal Dependencies
 */
import route from '@/utils/route';

const getBidTotal = () => document.querySelector('.js-bid-total');
const getMinBid = () => document.querySelector('.js-min-bid');
const getProductId = () => window._bdmDealOptions.productId;

const fetchBidInfo = async () => {
  const response = await axios.get(route('auctions.fetch', {
    product: getProductId(),
  }));

  return response?.data ?? {};
};

const updateCurrentBid = async () => {
    const $bidTotal = getBidTotal();
    const $minBid = getMinBid();

    try {
      const { currentBid, minimumNextBid } = await fetchBidInfo();
      if (currentBid && $bidTotal) {
        $bidTotal.textContent = `$${currentBid}`;
      }

      if (minimumNextBid && $minBid) {
        $minBid.textContent = `$${minimumNextBid}`;
      }
    } catch (error) {
      console.error('Error while fetching current bid', error);
    }
};

export default updateCurrentBid;
